var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Vuexy")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","rounded":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Daftar Akun ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Silahkan mengisi form dibawah ini ! ")]),_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Nama","label-for":"register-nama"}},[_c('validation-provider',{attrs:{"name":"nama","vid":"nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-nama","name":"register-nama","state":errors.length > 0 ? false : null,"placeholder":"Masukkan Nama"},model:{value:(_vm.formDaftar.nama),callback:function ($$v) {_vm.$set(_vm.formDaftar, "nama", $$v)},expression:"formDaftar.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"Masukkan Email"},model:{value:(_vm.formDaftar.email),callback:function ($$v) {_vm.$set(_vm.formDaftar, "email", $$v)},expression:"formDaftar.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NIK","label-for":"register-nik"}},[_c('validation-provider',{attrs:{"name":"nik","vid":"nik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-nik","name":"register-nik","state":errors.length > 0 ? false : null,"placeholder":"Masukkan NIK"},model:{value:(_vm.formDaftar.nik),callback:function ($$v) {_vm.$set(_vm.formDaftar, "nik", $$v)},expression:"formDaftar.nik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Foto KTP","label-for":"register-Foto"}},[_c('validation-provider',{attrs:{"name":"fotoktp","vid":"fotoktp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Pilih file anda ...","drop-placeholder":"Taruh disini ...","accept":"image/*"},on:{"change":function($event){return _vm.handleImage(_vm.e)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticStyle:{"cursor":"pointer"},attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.registerSubmit()}}},[_vm._v(" Daftar ")])],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah mempunyai akun ?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v("  Login disini")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }