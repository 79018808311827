<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">Vuexy</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid rounded :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Daftar Akun </b-card-title>
          <b-card-text class="mb-2">
            Silahkan mengisi form dibawah ini !
          </b-card-text>

          <!-- form -->
          <b-form class="auth-register-form mt-2">
            <!-- nama -->
            <b-form-group label="Nama" label-for="register-nama">
              <validation-provider
                #default="{ errors }"
                name="nama"
                vid="nama"
                rules="required"
              >
                <b-form-input
                  id="register-nama"
                  v-model="formDaftar.nama"
                  name="register-nama"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukkan Nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group label="Email" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="register-email"
                  v-model="formDaftar.email"
                  name="register-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukkan Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- nik -->
            <b-form-group label="NIK" label-for="register-nik">
              <validation-provider
                #default="{ errors }"
                name="nik"
                vid="nik"
                rules="required"
              >
                <b-form-input
                  id="register-nik"
                  v-model="formDaftar.nik"
                  name="register-nik"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukkan NIK"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Foto -->
            <b-form-group label="Foto KTP" label-for="register-Foto">
              <validation-provider
                #default="{ errors }"
                name="fotoktp"
                vid="fotoktp"
                rules="required"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Pilih file anda ..."
                  drop-placeholder="Taruh disini ..."
                  @change="handleImage(e)"
                  accept="image/*"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              block
              style="cursor: pointer"
              @click="registerSubmit()"
            >
              Daftar
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>Sudah mempunyai akun ?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp; Login disini</span>
            </b-link>
          </p>

          <!-- divider -->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import { BFormFile } from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import axios from "@axios";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      file: null,

      formDaftar: {
        nik: "",
        email: "",
        nama: "",
        status: 0,
        foto_ktp_64: "",
      },

      registration_token: "",

      // sideImg: require("@/assets/images/pages/register-v2.svg"),

      sideImg: require("@/assets/images/bannerHome2.jpg"),

      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    registerSubmit() {
      if (
        this.formDaftar.nama === "" ||
        this.formDaftar.nik === "" ||
        this.formDaftar.email === "" ||
        this.formDaftar.foto_ktp_64 === ""
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Form belum lengkap",
            icon: "XCircleIcon",
            variant: "danger",
            text: `Silahkan melengkapi form pendaftaran`,
          },
        });
      } else {
        return new Promise(() => {
          axios
            .post("v1/user/", this.formDaftar)
            .then((res) => {
              this.$router.push(
                "/verify-email-register/" + this.formDaftar.email
              );

              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      }
    },

    handleImage() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.formDaftar.foto_ktp_64 = rawImg;
      };
      reader.readAsDataURL(file);
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
